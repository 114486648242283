export default defineNuxtRouteMiddleware((to) => {
  const user = useSupabaseUser()

  watch(user, async (value) => {
    if (!value) {
      await navigateTo('/sign-in')
    }
  })

  if (!user.value && to.fullPath !== '/sign-in') {
    return navigateTo('/sign-in')
  }
})
